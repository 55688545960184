body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.errorModalStyle .ant-modal-body {
  border: 3px solid red;
}

.successModalStyle .ant-modal-body {
  border: 3px solid limegreen
}

.failModalStyle .ant-modal-body {
  border: 3px solid red;
}

.confirmationModalStyle .ant-modal-body {
  border: 3px solid orange;
}

.confirmationModalStyleV2 .ant-modal-body {
  border: 3px solid orange;
  width: 600px;
  background-color: white;
}

.withdrawPageBankList_title .ant-statistic-title {
  font-size: 14px;
}
