.expand-collapse-click-container {
  cursor: pointer;
}

.expand-collapse-sticky-container {
  position: sticky;
  top: 0;
}

.expand-collapse-container {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease-in-out;
}

.expand-collapse-container.is-open {
  grid-template-rows: 1fr;
}

.expand-collapse-children {
  overflow: hidden;
}
