.modifyColor-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 80vh;
  width: 100%;
  overflow: scroll;
}

.modifyColor-left-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 350px;
  min-width: 350px;
}

.modifyColor-left-action-section {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.modifyColor-scroll-container {
  overflow: scroll;
}

.modifyColor-iframe-mobile {
  flex: 1;
  max-width: 500px;
}

.modifyColor-iframe-desktop {
  min-width: 1500px;
}

.modifyColor-inspectmode-on {
  background: green;
}

.modifyColor-inspectmode-off {
  background: gray;
}

.modifyColor-draftname-container {
  background: #c4a484;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  padding: 5px;
}

.modifyColor-section-container {
  padding-bottom: 10px;
}

.modifyColorPage-expandCollapse-baseHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
}

.modifyColorPage-expandCollapse-title-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.modifyColorPage-expandCollapse-title {
  color: black;
  font-weight: bold;
}

.modifyColorPage-expandCollapse-icon {
  color: black;
}

.modifyColor-listing-container,
.modifyColor-sublisting-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modifyColor-sublisting-container {
  padding-top: 10px;
}

.modifyColor-input-label {
  font-size: 15px;
  font-weight: bold;
}

.modifyColor-draftListing-main-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
}

.modifyColor-draftListing-separator {
  height: 1px;
  background: gray;
}

.modifyColor-draftListing-label-section {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.modifyColor-draftListing-action-section {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.modifyColor-draftListing-load-btn {
  background: rgb(32, 245, 32);
  border: none;
}

.modifyColor-draftListing-load-btn:hover {
  background: rgba(32, 245, 32, 0.5);
}

.modifyColor-draftListing-delete-btn {
  background: rgb(235, 77, 77);
  border: none;
}

.modifyColor-draftListing-delete-btn:hover {
  background: rgba(235, 77, 77, 0.5);
}

.modifyColor-targetElement-subSectionTitle {
  font-weight: bold;
  text-decoration: underline;
}

.modifyColor-level0-header {
  background: #529ee6;
}

.modifyColor-level0-txt {
  color: #ffffff;
}

.modifyColor-level1-header {
  background: #b6d7f6;
}

.modifyColor-level1-txt {
  color: #000000;
}

.modifyColor-level2-header {
  background: #ffffff;
  border: 2px solid #529ee6;
}

.modifyColor-level2-txt {
  color: #000000;
}

.modifyColor-level2-header {
  background: #ffffff;
  border: 1px solid #529ee6;
}

.modifyColor-level2-txt {
  color: #000000;
}

.modifyColor-input {
  background: #ffffff;
  border: 1px solid #dcd8d8;
  border-radius: 5px;
}

.modifierImagePicker-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.modifierImagePicker-preview-container {
  display: flex;
  flex-direction: column;
}

.modifierImagePicker-preview-img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.modifierImagePicker-btn-container {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid black;
  cursor: pointer;
  align-self: flex-start;
  box-shadow: 2px 2px 2px black;
}
